


























































































import { Component, Vue } from 'vue-property-decorator'
import { apiDistributionMemberDetails } from '@/api/distribution/distribution'
@Component
export default class AddSupplier extends Vue {
    /** S Data **/

    id!: any //当前的ID

    distributionData = {}

    /** E Data **/

    /** S Method **/

    // 获取申请分销详情
    getDetailsData() {
        apiDistributionMemberDetails({ id: this.id }).then((res: any) => {
            this.distributionData = { ...res }
        })
    }

    /** E Method **/

    created() {
        this.id = this.$route.query.id
        this.id && this.getDetailsData()
    }
}
